import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Lambo Fund Help" />
    <h3>Current Progress</h3>
    <p>0 BTC</p>

    <h3>How You Can Help</h3>
    <ul>
      <li>
        <b>BTC</b>: bc1qx389l9vlzut6sj8dd2ea4dausjsx7vd5qrvwc9
      </li>
    </ul>

    <h3>How Much Does a Lamborghini Cost?</h3>
    <ul>
      <li>
        Lamborghini Urus - <i>$211,321</i>
      </li>
      <li>
        Lamborghini Huracan Evo - <i>$264,969</i>
      </li>
      <li>
        Lamborghini Huracan Evo Spyder - <i>$291,095</i>
      </li>
      <li>
        Lamborghini Huracan Evo RWD - <i>$212,266</i>
      </li>
      <li>
        Lamborghini Huracan Evo RWD Spyder - <i>$233,123</i>
      </li>
      <li>
        Lamborghini Aventador S - <i>$421,350</i>
      </li>
      <li>
        Lamborghini Aventador S Roadster - <i>$460,427</i>
      </li>
      <li>
        Lamborghini Aventador SVJ - <i>$521,465</i>
      </li>
      <li>
        Lamborghini Aventador SVJ Roadster - <i>$577,661</i>
      </li>
    </ul>
  </Layout>
)

export default IndexPage
